import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import { auth } from '../../../firebase';
import { createUser } from '../../../services/user';
import { AppDispatch } from '../../store';
import { setUser as setUserDB, setUsers } from '../user/reducer';
import { setUser } from './reducer';
import { SignInProps, SignUpProps } from './types';
import { setCourses, setNewQuizzes, setQuizzes, setUserData } from '../canvas/reducer';
import * as Sentry from '@sentry/react'

export const isAuth = () => (dispatch: AppDispatch) => {
  const sub = onAuthStateChanged(auth, (user) => {
    dispatch(setUser(user?.toJSON() as any));
  });
  return () => sub();
};


export const signIn = ({
  email, password
}: SignInProps) => async (dispatch: AppDispatch) => {

  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  dispatch(setUser(userCredential.user.toJSON() as any))
  Sentry.setUser({ email, userId: userCredential.user.uid })

  return userCredential

}

export const signUp = ({
  email, password,
}: SignUpProps) => async (dispatch: AppDispatch) => {

  const userCredential = await createUserWithEmailAndPassword(auth, email, password)
  const uid = userCredential.user.uid

  await createUser(uid, {
    email,
    role: 'Teacher',
    createdAt: new Date(),
  })

  Sentry.setUser({ email, userId: uid, })

  dispatch(setUser(userCredential.user.toJSON() as any))

  return userCredential

}

export const signOutUser = () =>
  async (dispatch: AppDispatch) => {

    const authInstance = getAuth();
    dispatch(setUser(undefined as any))
    dispatch(setUserDB(undefined as any))
    dispatch(setUserData(undefined as any))
    dispatch(setNewQuizzes({}))
    dispatch(setQuizzes({}))
    dispatch(setCourses([]))
    dispatch(setUsers([]))
    Sentry.setUser(null)

    await signOut(authInstance)

    return

  };