import useHandleEvent from "../../../hooks/useHandleEvent"
import useModal from "../../../hooks/useModal"
import { UserDBI } from "../../../models/user"
import { updateUserStatus } from "../../../services/user"
import { showToastSuccess } from "../../../utils/toast"
import { isActiveUser } from "../../../utils/user"
import IconProhibited from "../../Icon/Prohibited"
import UIConfirmDialog from "../../UI/ConfirmDialog"

const UserTableIconProhibited = ({ user }: { user: UserDBI }) => {

    const { handleEvent, isLoading } = useHandleEvent()
    const { isModalVisible, hideModal, showModal } = useModal()
    const userMessage = isActiveUser(user.active) ? 'disabled' : 'enabled'

    const handleDelete = () => handleEvent(async () => {
        hideModal()
        await updateUserStatus({
            userId: user.uid,
            active: !isActiveUser(user.active)
        })
        showToastSuccess(`The user has been ${userMessage}`)
    })

    return (
        <>
            <IconProhibited width="15" height="15" onClick={showModal} disabled={isLoading} />
            <UIConfirmDialog isOpen={isModalVisible} onCancel={hideModal}
                onConfirm={handleDelete} message={`The user ${user.email} will be ${userMessage}`} />
        </>
    )
}

export default UserTableIconProhibited
