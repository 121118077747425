import { Link } from "react-router-dom"
import { CourseI } from "../../models/canvas/course"
import { useAppSelector } from "../../redux/store"
import { formatDate } from "../../utils/dates"
import { ROUTES } from "../../constants/routes"
import { getCourseName } from "../../utils/course"

interface Props {
  item: CourseI
}
const CourseCard = ({ item }: Props) => {

  const { quizzes, newQuizzes } = useAppSelector(state => state.canvas)
  const totalQuizzes = (quizzes && quizzes[item.id]?.length) || 0
  const totalNewQuizzes = (newQuizzes && newQuizzes[item.id]?.length) || 0
  const numberOfQuizzes = (totalNewQuizzes + totalQuizzes) || undefined

  return (
    <Link to={`${ROUTES.DASHBOARD_COURSES}/${item.id}`}>
      <div className="max-w-sm rounded overflow-hidden">
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{getCourseName(item)}</div>
        </div>
        {numberOfQuizzes && (
          <div className="px-6 pt-4 pb-2">
            <span className="inline-block bg-backgroundAccent rounded-full px-3 py-1 text-sm font-semibold text-gameBlue mr-2 mb-2">
              {numberOfQuizzes} quizzes found in course
            </span>
          </div>
        )}
        <div className="px-6 pt-4 pb-2">
          <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {formatDate(item.created_at)}
          </span>
        </div>
      </div>
    </Link>
  )
}

CourseCard.displayName = 'CourseCard';
export default CourseCard
