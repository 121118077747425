import { ChangeEvent } from 'react';
import { UIInputProps } from './props';

const UIInput = ({
    icon,
    width,
    label,
    name,
    placeholder,
    labelClassName = 'sr-only',
    disabled,
    max,
    min,
    onChange,
    ...rest
}: UIInputProps) => {

    const labelText = label || placeholder

    const getFormEventData = (e:any) => ({
        target:{
            value:e,
            name,
        }
    } as any)


    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const currentValue = Number(e.target.value);
    
        if (min === undefined && max === undefined) {
          return onChange && onChange(e);
        }
    
        if (currentValue <= Number(min)) {
          return onChange && onChange(e);
        }
    
        if (currentValue >= Number(max)) {
          return onChange && onChange(getFormEventData(max));
        }
    
        onChange && onChange(e);
      };

    return (
        <div
        className={`flex flex-col ${width ? width : 'w-full'} my-4`}>
            
            {labelText && (
                <label htmlFor={name} className={labelClassName}>
                    {labelText}
                </label>
            )}
            <div className={`relative flex items-center border-fade border-gameBlue border-2 py-2 px-7 rounded-3xl ${disabled && 'bg-slate-100'}`}>
                 {icon && icon}
                <input
                    id={name}
                    name={name}
                    disabled={disabled}
                    placeholder={placeholder}
                    min={min}
                    max={max}
                    onChange={handleChange}
                    className={`flex flex-1 appearance-none ${width ? width : 'w-1/2'
                        } py-3 ml-2 leading-tight outline-none border-0 focus:outline-none focus:shadow-none shadow-none p-0 disabled:cursor-not-allowed`}
                    {...rest}
                />
            </div>
        </div>
    );

}

UIInput.displayName = 'Input';

export default UIInput;
