import { FormEvent, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ROUTES } from "../../../constants/routes"
import UseForm from "../../../hooks/useForm"
import useHandleEvent from "../../../hooks/useHandleEvent"
import { GAME_TYPES } from "../../../models/game"
import { GAME_PRIZES } from "../../../models/prize"
import { useAppSelector } from "../../../redux/store"
import { createGameFromForm } from "../../../services/game"
import { showToastSuccess } from "../../../utils/toast"
import { GameFormI, GameFormProps } from "./props"

const useGameForm = ({ quiz, quizQuestions }: GameFormProps) => {

    const numberOfQuestions = quiz.question_count ||  quizQuestions.length || 0
    const { handleEvent, isLoading } = useHandleEvent()
    const navigate = useNavigate()
    const { courseId } = useParams()
    const { user } = useAppSelector(state => state.user)
    const [coverImage, setCoverImage] = useState<File>()
    const { onChange, formData } = UseForm<GameFormI>({
        name: quiz.title,
        gameType: GAME_TYPES.RACING_GAME,
        prize: GAME_PRIZES.TROPHY,
        durationBetweenAnswers: 5,
        numberOfQuestions: numberOfQuestions,
    })

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => handleEvent(async () => {

        e.preventDefault()

        const gameSnap = await createGameFromForm({
            quizId: quiz.id, formData, courseId: String(courseId),
            quizQuestions, coverImage, userId: user.uid,
            type: quiz.type || 'quiz',
        })

        showToastSuccess("The game has been created")

        navigate(`${ROUTES.DASHBOARD_GAMES}/${gameSnap.id}`)


    })

    return {
        handleSubmit,
        isLoading,
        onChange,
        formData,
        setCoverImage,
        numberOfQuestions
    }

}

export default useGameForm