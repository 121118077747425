import { useState } from 'react';
import ClipboardIconSolid from './IconSolid';
import ClipboardIcon from './Icon';

interface Props {
    href: string
}

const ClipboardCopy = ({ href }: Props) => {
    const [isCopied, setIsCopied] = useState(false);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text: string) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(href)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    return (
        <>
            <div className="flex justify-start items-center">
                <div
                    className="border-2 border-gray-400 bg-gray-300 overflow-x-scroll py-4 px-2 rounded-xl "
                    style={{ fontSize: '8px' }}>
                    {href}
                </div>
                <button onClick={handleCopyClick}>
                    <span>
                        {isCopied ? (
                            <ClipboardIconSolid />
                        ) : (
                            <ClipboardIcon />
                        )}
                    </span>
                </button>
            </div>
        </>
    );
};

export default ClipboardCopy;
