import { useEffect } from "react"
import { createPortal } from "react-dom"
import useKeyPress from "../../../hooks/useKeyPress"
import { UIModalProps } from "./props"


interface IconCloseProps {
    onClose?: () => void
}

const IconClose = ({ onClose }: IconCloseProps) => {
    return (
        <button type="button" onClick={onClose}
            className={`'rounded-lg text-gray-400' bg-transparent hover:bg-orange-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-orange-400 dark:hover:text-white`} data-modal-toggle="small-modal">
            <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </button>
    )
}

const UIModal = (props: UIModalProps) => {

    const {
        title, onClose, children,
    } = props

    const modalContainer: any = document.querySelector('#modal')

    const escapePressed = useKeyPress("Escape")

    useEffect(() => {
        escapePressed && (onClose && onClose())
    }, [escapePressed])

    return createPortal(
        <div className={`justify-center items-center backdrop-blur flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-black bg-opacity-40 z-[1000]`}>

            <div className={`relative sm:my-8 sm:align-middle sm:max-w-lg sm:w-full opacity-100 translate-y-0 sm:scale-100 my-6 rounded-xl overflow-hidden bg-white`}>
                <div className={` w-6-12 border-0 shadow-lg relative flex flex-col outline-none focus:outline-none h-full sm:h-[responsive]`}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '80vh',
                    }}>
                    <div className={`flex justify-between items-center`}>
                        <h3 className={`xs:text-lg lg:text-2xl font-semibold capitalize xs:text-lg p-1.5`}>
                            {title}
                        </h3>
                        {onClose && <IconClose onClose={onClose} />}
                    </div>
                    <div className={`relative flex-auto overflow-y-auto h-full p-6`}>
                        {children}
                    </div>

                </div>
            </div>
        </div>, modalContainer
    )
}

export default UIModal