export const formatDate = (isoDateString: string) => {

    const date = new Date(isoDateString);
    const options: Intl.DateTimeFormatOptions = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
    };
    const formatter = new Intl.DateTimeFormat('en-US', options);

    return formatter.format(date);

}

export const isDateFinished = (isoDateString:string) => {

    const now = new Date()

    return new Date(isoDateString) < now

}