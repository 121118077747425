import { useLocation } from 'react-router-dom';
import logo from '../../assets/img/logo-small.png';
import { ROUTES } from '../../constants/routes';
import { useAppSelector } from '../../redux/store';
import SidebarItem from './Item';

const SidebarUserItem = () => {

    const { user } = useAppSelector(state => state.user)
    const location = useLocation();
    const currentPath = location.pathname;

    if(user?.role!=='Admin') return null

    return (
        <SidebarItem to={ROUTES.DASHBOARD_USERS} isAcive={currentPath == ROUTES.DASHBOARD_USERS}
            svgPath={<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>} label="Users" />
    )

}

const Sidebar = () => {

    const location = useLocation();
    const currentPath = location.pathname;

    return (
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
            <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 bg-white overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4">
                    <img className="h-8 w-auto" src={logo} alt="Workflow" />
                </div>
                <div className="mt-5 flex-grow flex flex-col">
                    <nav className="flex-1 px-2 pb-4 space-y-1">
                        {/*<SidebarItem to={ROUTES.DASHBOARD} isAcive={currentPath == ROUTES.DASHBOARD}
                            svgPath={<path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>} label="Home" />*/}
                         <SidebarItem to={ROUTES.DASHBOARD_COURSES} isAcive={currentPath == ROUTES.DASHBOARD_COURSES}
                            svgPath={<path strokeLinecap="round" strokeLinejoin="round" d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"></path>} label="Courses" />
                        <SidebarItem to={ROUTES.DASHBOARD_GAMES} isAcive={currentPath == ROUTES.DASHBOARD_GAMES}
                            svgPath={<path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>} label="Games" />
                        <SidebarUserItem />
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
