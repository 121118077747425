
interface Props {
    name: string,
    href: string
}

const Navbaritem = ({ name, href }: Props) => {
    return (
        <a
            href={href}
            className="text-base font-medium text-gameBlue hover:text-opacity-75">
            {name}
        </a>
    )
}

export default Navbaritem
