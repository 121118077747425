import { FormEvent } from "react"
import UseForm from "../../hooks/useForm"
import useHandleEvent from "../../hooks/useHandleEvent"
import { UserDBI } from "../../models/user"
import { updateUser } from "../../services/user"
import UIButton from "../UI/Button"
import UIInput from "../UI/Input"
import UIModal from "../UI/Modal"
import { showToastSuccess } from "../../utils/toast"

interface Props {
    onClose?: (() => void)
    user?: UserDBI
}

const ModalUser = ({ onClose, user }: Props) => {

    const {
        formData: {
            accessToken, email,
            schoolUrl, role,
            trophy, uid,
            fullName, phone,
        },
        onChange,
    } = UseForm({
        accessToken: user?.accessToken || '',
        schoolUrl: user?.schoolUrl || '',
        email: user?.email || '',
        role: user?.role,
        trophy: user?.trophy,
        uid: user?.uid,
        fullName: user?.fullName || '',
        phone: user?.phone || '',
    })
    const { isLoading, handleEvent } = useHandleEvent()

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {

        e.preventDefault()

        handleEvent(async () => {

            if (!user?.uid) return //TODO:SAVE

            await updateUser(user.uid, {
                accessToken,
                schoolUrl,
            })

            showToastSuccess("User is updated")
            onClose && onClose()

        })

    }

    return (
        <UIModal onClose={onClose} title="Edit user">
            <form onSubmit={onSubmit}>
                {role === 'Teacher' && <UIInput onChange={onChange} value={accessToken}
                    placeholder="Access Token" name="accessToken" />}
                {role === 'Teacher' && <UIInput onChange={onChange} value={schoolUrl}
                    placeholder="School Url" name="schoolUrl" />}
                <UIInput value={fullName} placeholder="Name" disabled={role === 'Teacher'} />
                <UIInput value={email} placeholder="Email" disabled />
                <UIInput value={role} placeholder="Role" disabled />
                {user?.phone && <UIInput value={phone} placeholder="Phone" disabled />}
                {role === 'Teacher' && <UIInput value={trophy} placeholder="Trophy" disabled />}
                <UIInput value={uid} placeholder="Uid" disabled />
                <UIButton type="submit" isLoading={isLoading}>
                    Update
                </UIButton>
            </form>
        </UIModal>
    )
}

export default ModalUser
