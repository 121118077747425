import { useEffect } from "react"
import UseForm from "../../hooks/useForm"
import UIInput from "../UI/Input"

interface Props {
    onChange?: (data: { email: string, password: string }) => void,
}

const AuthFormBase = ({ onChange }: Props) => {

    const {
        onChange: onChangeForm,
        formData,
    } = UseForm({
        email: "",
        password: "",
    })

    useEffect(() => {
        onChange && onChange(formData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    return (
        <>
            <UIInput onChange={onChangeForm} required name="email" type="email" placeholder="account@gamify.com" />
            <UIInput onChange={onChangeForm} required name="password" placeholder="Your password" type="password" />
        </>
    )
}

export default AuthFormBase
