import { GAME_SECTIONS } from "../../constants/ui"
import { useAppSelector } from "../../redux/store"
import UIGridList from "../UI/Grid/List"
import GameCard from "./Card"

interface Props {
    section?: GAME_SECTIONS
}

const GameList = ({ section }: Props) => {

    const { games } = useAppSelector(state => state.game)

    const items = (() => {

        if (!section) return games

        if (section === GAME_SECTIONS.ACTIVE_GAMES) {
            return games.filter(e => !e.archived)
        }

        return games.filter(e => e.archived)

    })()

    return (
        <UIGridList component={GameCard} items={items} />
    )
}

export default GameList
