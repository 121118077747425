import { GAME_PRIZE_ITEMS, GAME_TYPE_ITEMS } from "../../../constants/game"
import UIButton from "../../UI/Button"
import UIInput from "../../UI/Input"
import UISelect from "../../UI/Select"
import GameFormQuizAbstract from "./QuizAbstract"
import { GameFormProps } from "./props"
import useGameForm from "./useGameForm"

const GameForm = ({ quiz, quizQuestions, courseName }: GameFormProps) => {


    const {
        handleSubmit, onChange, formData, isLoading, numberOfQuestions
    } = useGameForm({ quiz, quizQuestions })


    const classLabel = "relative  top-[11px] z-20 w-fit ml-3 px-2 bg-[#fff]"

    return (
        <div className="w-full flex justify-center items-center flex-col">
            <h3 className="text-4xl font-semibold my-2">Gamify your content</h3>
            <GameFormQuizAbstract title={quiz.title} numberOfQuestions={numberOfQuestions} courseName={courseName}/>
            <form onSubmit={handleSubmit} className="w-full">
                {/*<SelectFile onChange={e => {
                    setCoverImage(e)
                }} />*/}
                <UIInput placeholder="Quiz name" name="name"
                    width="w-full" onChange={onChange} labelClassName={classLabel}
                    required value={formData.name} />
                <UIInput placeholder="Total questions"  type="number"
                    width="w-full" onChange={onChange} name="numberOfQuestions"
                    min={1} max={numberOfQuestions} labelClassName={classLabel}
                    required value={formData.numberOfQuestions} />
                <UIInput placeholder="Seconds to answer each question"
                    width="w-full" onChange={onChange} name="durationBetweenAnswers" type="number"
                    min={1} labelClassName={classLabel}
                    required value={formData.durationBetweenAnswers} />
                <UISelect items={GAME_TYPE_ITEMS} onChange={onChange} name="gameType"
                    required value={formData.gameType} />
                <UISelect items={GAME_PRIZE_ITEMS} onChange={onChange} name="prize"
                    required value={formData.prize} />
                <UIButton type="submit" width="w-full" isLoading={isLoading}>
                    Gamify me
                </UIButton>
            </form>
        </div>
    )
}

export default GameForm
