import useHandleEvent from "../../../hooks/useHandleEvent";
import { useAppSelector } from "../../../redux/store";
import { updateGame } from "../../../services/game"
import UIButton from "../../UI/Button"

const GameDetailButtonArchive = () => {

    const { game } = useAppSelector((state) => state.game);
    const text = !game?.archived ? 'Archive game' : 'Activate game'
    const { handleEvent, isLoading } = useHandleEvent()

    const handleClick = () => handleEvent(async () => await updateGame(game.id, { archived: !game.archived }))

    if(game?.archived) return null

    return (
        <UIButton variant="secondary"
            onClick={handleClick}
            isLoading={isLoading}
        >
            {text}
        </UIButton>
    )
}

export default GameDetailButtonArchive
