import { useNavigate } from "react-router-dom"
import AuthFormBase from "../components/Auth/FormBase"
import AuthLayout from "../components/Auth/Layout"
import AuthLinks from "../components/Auth/Links"
import { SIGN_UP_LINKS } from "../components/Auth/constants"
import useAuthForm from "../components/Auth/useAuthForm"
import { signUp } from "../redux/modules/auth/actions"
import { useAppDispatch } from "../redux/store"
import { ROUTES } from "../constants/routes"

const SignUpPage = () => {

  const {
    isLoading, setFormData,
    email, password, handleEvent,
  } = useAuthForm()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onSubmit = () => handleEvent(async () => {
    await dispatch(signUp({ email, password }))
    navigate(ROUTES.NEW_USER)
  })

  return (
    <AuthLayout title="Signup your school to Gamify" buttonText="Sign up"
      onSubmit={onSubmit} isLoading={isLoading}>
      <AuthFormBase onChange={setFormData} />
      <AuthLinks items={SIGN_UP_LINKS} />
    </AuthLayout>
  )
}

export default SignUpPage
