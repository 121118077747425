import { ReactNode } from "react";
import { Link } from "react-router-dom";  // Importa Link desde react-router-dom

interface Props {
    label: string,
    to: string,  // Cambia href a to
    svgPath: ReactNode
    isAcive?:boolean
}

const SidebarItem = ({ to, svgPath, label, isAcive }: Props) => (
    <Link className={`text-gray-600 cursor-pointer hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm 
    font-medium rounded-md ${isAcive && "bg-gray-100 text-gray-900"}`} to={to}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" 
        className={`text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6 ${isAcive && "text-gray-600"}`}>
            {svgPath}
        </svg>
        {label}
    </Link>
);

export default SidebarItem;
