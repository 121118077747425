import { useAppSelector } from "../../redux/store"
import UIGridList from "../UI/Grid/List"
import QuizCard from "./Card"

interface Props {
  courseId: string
  courseName?: string
}

const QuizList = ({ courseId, courseName }: Props) => {

  const { quizzes, newQuizzes } = useAppSelector(state => state.canvas)

  const items = [
    ...(quizzes?.[courseId] || []),
    ...(newQuizzes?.[courseId] || [])
  ];

  return (
    <UIGridList component={QuizCard} items={items} courseName={courseName}/>
  )
}

export default QuizList
