import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserState } from "./types";
import { UserDBI } from '../../../models/user';

const initialState: UserState = {
    user: undefined as any,
    users: [],
}

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setUsers: (state, action: PayloadAction<UserDBI[]>) => {
            state.users = action.payload;
        },
    },
})

export const { setUser, setUsers } = UserSlice.actions;

export default UserSlice.reducer