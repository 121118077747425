import { useAppSelector } from "../../../redux/store";
import gamifyLogo from '../../../assets/img/logo-big.png';

const GameDetailLogo = () => {

    const { game } = useAppSelector((state) => state.game);
    const src = game.coverImage || gamifyLogo

    return (
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <img
                className={`max-h-48 w-auto ${game.archived && 'grayscale'}`}
                src={src}
                alt={game.name}
            />
        </div>
    )
}

export default GameDetailLogo
