import UICard from "../Card";
import UIGrid from ".";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";

interface HasName {
    name?: string;
    title?: string;
}
interface UIGridListProps<T> {
    items: T[];
    courseName?:string;
    component: React.ComponentType<any>;
}

const UIGridList = <T extends HasName,>({ items, courseName, component: Component }: UIGridListProps<T>) => {

    const search = useSelector((state: RootState) => state.search.search);
    if(!items) return null

    const filteredItems = items.filter(item => {

        if(!search) return true 
        
        if(item.name) return item.name.toLowerCase().includes(search?.toLowerCase())
        
        if(item.title) return item.title.toLowerCase().includes(search?.toLowerCase())

        return true
    });

    return (
        <UIGrid>
            {filteredItems.map((item, i) => (
                <UICard key={i}>
                    <Component item={item} courseName={courseName}/>
                </UICard>
            ))}
        </UIGrid>
    );
};

export default UIGridList;