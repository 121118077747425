import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useAppSelector } from "../../redux/store";

interface NavbarLinkProps {
    text: string,
    className: string,
    to: string
}

const NavbarLink = ({ to, className, text }: NavbarLinkProps) => (
    <Link to={to} className={` inline-block py-2 px-4 border border-transparent rounded-md text-base font-medium ${className}`}>
        {text}
    </Link>
);

const primaryButtonClassName = "bg-accentYellow text-white hover:bg-opacity-75 text-center"

const NavbarItemsAuth = () => {

    const { user } = useAppSelector(state => state.auth);

    if (!user) {
        return (
            <>
                <NavbarLink
                    to={ROUTES.SIGN_IN}
                    className={primaryButtonClassName}
                    text="Sign in"
                />
            </>
        );
    }

    return (
        <NavbarLink
            to={ROUTES.DASHBOARD}
            className={primaryButtonClassName}
            text="Dashboard"
        />
    );
}

export default NavbarItemsAuth;
