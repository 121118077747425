import { ReactNode } from 'react'

const UICard = ({ children }: { children: ReactNode }) => {
    return (
        <div className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
            {children}
        </div>
    )
}

export default UICard
