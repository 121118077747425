import {
    createBrowserRouter,
} from "react-router-dom";
import { ROUTES } from "../constants/routes";
import DashboardLayout from "../layouts/Dashboard";
import HomeLayout from "../layouts/Home";
import HomePage from "../pages";
import NewUserPage from "../pages/new-user";
import RecoverPasswordPage from "../pages/recover-password";
import SignInPage from "../pages/sign-in";
import SignUpPage from "../pages/sign-up";
import RequireAuth from "./RequireAuth";
import DashboardGamesPage from "../pages/dashboard/games";
import DashboardCoursesPage from "../pages/dashboard/courses";
import DashboardPage from "../pages/dashboard";
import DashboardCoursesIdPage from "../pages/dashboard/courses/[courseId]";
import DashboardGamesIdPage from "../pages/dashboard/games/[gameId]";
import DashboardUsersPage from "../pages/dashboard/users";
import * as Sentry from '@sentry/react'

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
    createBrowserRouter,
);

export const router = sentryCreateBrowserRouter([
    {
        path: ROUTES.HOME,
        element: <HomeLayout />,
        children: [
            {
                path: ROUTES.HOME,
                element: <HomePage />,
            },
            {
                path: ROUTES.SIGN_IN,
                element: <SignInPage />,
            },
            {
                path: ROUTES.SIGN_UP,
                element: <SignUpPage />,
            },
            {
                path: ROUTES.RECOVER_PASSWORD,
                element: <RecoverPasswordPage />,
            },
            {
                path: ROUTES.NEW_USER,
                element: <RequireAuth><NewUserPage /></RequireAuth>,
            }
        ]
    },
    {
        path: ROUTES.DASHBOARD,
        element: <RequireAuth><DashboardLayout /></RequireAuth>,
        children: [
            {
                path:'',
                element:<DashboardPage />,
            },
            {
                path:"games",
                element:<DashboardGamesPage />,
            },
            {
                path:"games/:gameId",
                element:<DashboardGamesIdPage />,
            },
            {
                path:"courses",
                element:<DashboardCoursesPage />,
            },
            {
                path:"courses/:courseId",
                element:<DashboardCoursesIdPage />,
            },
            {
                path:"users",
                element:<DashboardUsersPage />,
            },
        ],
    }
]);