import { useState } from "react"

const useModal = (initialValue = false) => {

    const [isModalVisible, setIsModalVisible] = useState(initialValue)

    const hideModal = () => setIsModalVisible(false)
    const showModal = () => setIsModalVisible(true)

    return {
        showModal,
        hideModal,
        isModalVisible,
        setIsModalVisible,
    }

}

export default useModal