import { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { isAuth } from './redux/modules/auth/actions'
import { subscribeToUserChanges } from './redux/modules/user/actions'
import { useAppDispatch, useAppSelector } from './redux/store'
import { router } from './router'

const App = () => {

    const dispatch = useAppDispatch()
    const { user } = useAppSelector(state => state.auth)

    useEffect(() => {
        dispatch(isAuth())
    }, [])

    useEffect(() => {

        if (!user) return

        if (user.isAnonymous) return

        dispatch(subscribeToUserChanges(user.uid))

    }, [user])

    return (
        <>
            <RouterProvider router={router} />
        </>
    )
}

export default App