import { HeadTable, PropsTable } from "./props"

export const UITableBody = ({ body, head }: PropsTable) => {
    return (
        <tbody>
            {body.map((item, i) => <TableRow item={item} head={head} key={i} />)}
        </tbody>
    )
}

const getText = ({ columnItem, item }: { columnItem: any, item: any }) => {

    if (columnItem.value.includes('.')) {
        const itemSplit = columnItem.value.split('.')
        return item[itemSplit[0]][itemSplit[1]]
    }

    return item[columnItem.value]

}

const TableRow = ({ item, head }: { head: HeadTable, item: any }) => (
    <tr className="bg-white dark:border-gray-700">
        {head.map((columnItem: any, index) => {

            const child = item[columnItem.value]

            if (typeof child === 'object') return <td key={index}>{child}</td>

            const text = getText({ columnItem, item })

            return (
                <td className="px-6 py-4" key={index}>
                    {text}
                </td>
            )
        })}
    </tr>
)