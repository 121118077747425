import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { ROUTES } from '../constants/routes';
import { useAppSelector } from '../redux/store';

interface Props {
    children: ReactNode
    to?: string
}

const RequireAuth = ({ children, to = ROUTES.SIGN_IN }: Props) => {

    const { user } = useAppSelector(state => state.auth)

    if (!user) return <Navigate to={to} replace />

    return children

};

export default RequireAuth;
