import { useSelector } from "react-redux"
import { UIPagination } from "../Pagination"
import { UITableBody } from "./Body"
import { UITableHead } from "./Head"
import { PropsTable } from "./props"
import useTable from "./useTable"
import { RootState } from "../../../redux/store"
import Title from "../Title/Title"

export const UITable = ({ body, head }: PropsTable) => {

    const search = useSelector((state: RootState) => state.search.search);
    const pageSize = 10
    //const { formData: { search }, onChange } = UseForm({ search: '' })
    const { dataFiltered, dataPaginate, setCurrentPage, currentPage } = useTable({ body, head, search, pageSize })
    const getTotalCount = () => !search ? body.length : dataFiltered().length

    return (
        <>
            <Title title="Users" />

            <div className='m-auto mt-3 max-w-5xl relative overflow-x-auto shadow-md sm:rounded-lg'>
                <table className=" w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <UITableHead head={head} />
                    <UITableBody body={dataPaginate()} head={head} />
                </table>

            </div>
            <div className="sm:my-3 my-8 text-center">
                <UIPagination
                    currentPage={currentPage}
                    totalCount={getTotalCount()}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                />
            </div>
        </>
    )
}