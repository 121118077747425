import { useAppSelector } from '../../../redux/store';

const Item = ({ title, value }: { title: string, value: string }) => {
    return (
        <div className="mt-4 max-w-5xl mx-auto px-4 pb-4 sm:px-6 lg:px-8">
            <h3 className="text-base text-gray-500">{title}</h3>
            <p className="text-xl text-gameBlue font-bold">{value}</p>
        </div>
    );
};

const GameDetailItems = () => {

    const { game } = useAppSelector((state) => state.game);

    return (
        <>
            <Item title='Game Type' value={game.gameType} />
            <Item title='Prize' value={game.prize} />
            <Item title='Number of questions' value={(game.questions?.length || 0).toString()} />
        </>
    )
}

export default GameDetailItems
