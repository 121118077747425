import React from 'react';
import { ItemI } from "../../../models/item";

const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');

interface Props {
    items: ItemI[];
    value?: string;
    onClick?: (item: ItemI) => void;
}

const UITabs: React.FC<Props> = React.memo(({ items, value, onClick }) => {
    return (
        <div className="mt-6 sm:mt-2 2xl:mt-5">
            <div className="border-b border-gray-200">
                <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {items.map((item, i) => {
                            const isActive = item.label === value;
                            return (
                                <div
                                    key={i} // Asumiendo que `item` tiene un campo `id` único
                                    onClick={() => onClick?.(item)}
                                    className={classNames(
                                        isActive ? 'border-gameBlue text-gameBlue fort-bold' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                                    )}
                                    aria-current={isActive ? 'page' : undefined}>
                                    {item.label}
                                </div>
                            );
                        })}
                    </nav>
                </div>
            </div>
        </div>
    );
});

export default UITabs;
