import { FC } from 'react'

interface PropsItem {
    index: number,
    active?: boolean,
    onPageChange: (n: number) => void
}

export const UIPaginationItem: FC<PropsItem> = ({ index, active, onPageChange }) => {

    const className = !active ? 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700'
        : 'text-blue-600 bg-blue-50  border-blue-200 hover:bg-blue-100 hover:text-blue-700'

    return (
        <li onClick={() => { onPageChange(index) }} className={`${className} cursor-pointer sm:py-2 sm:px-3 py-5 px-5 leading-tight border`} >
            {index}
        </li>
    )
}