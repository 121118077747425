import { createPortal } from 'react-dom'
import { Dots } from './Dots'
import styles from './styles.module.css'

const Loading = () => {

    const modalContainer: any = document.querySelector('#loading')

    return createPortal(
        <div className={`h-screen w-screen fixed z-[1000] bg-gray-100 opacity-50`}>
            <div className={`p-5 z-[10000] rounded-full items-center justify-center w-screen h-screen flex space-x-3 ${styles.loader}`}>
                <Dots />
            </div>
        </div>,
        modalContainer
    )
}

export default Loading