export const generatePinCode = () => {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';

    while (code.length < 4) {
        const randomCharacter = characters.charAt(Math.floor(Math.random() * characters.length));

        if (code.indexOf(randomCharacter) === -1) {
            code += randomCharacter;
        }
    }

    return code;
}

export const removeAccents = (e: string) => {

    const accentMap: any = { 'á': 'a', 'é': 'e', 'è': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'a', 'É': 'e', 'Í': 'i', 'Ó': 'o', 'Ú': 'u' }

    let ret = '';

    for (let i = 0; i < e?.length; i++) {
        ret += accentMap[e.charAt(i)] || e.charAt(i);
    }

    return ret.toLowerCase()

}