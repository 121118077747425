interface RequestOptions {
    headers?: { [key: string]: string };
    params?: { [key: string]: string };
}

class HTTPClient {
    private baseURL: string;

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    private buildURL(url: string, params?: { [key: string]: string }): string {
        const queryParams = params
            ? '?' + new URLSearchParams(params).toString()
            : '';
        return `${this.baseURL}${url}${queryParams}`;
    }

    public async get<T>(url: string, options?: RequestOptions): Promise<T> {
        const finalURL = this.buildURL(url, options?.params);
        const response = await fetch(finalURL, {
            method: 'GET',
            headers: options?.headers,
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    }

    public async post<T>(
        url: string,
        body: any,
        options?: RequestOptions
    ): Promise<T> {
        const finalURL = this.buildURL(url, options?.params);
        const response = await fetch(finalURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...options?.headers,
            },
            body: JSON.stringify(body),
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    }
}

export default HTTPClient;
