import canvasLogo from '../assets/img/canvas-logo.png';
import logo from '../assets/img/logo-big.png';

const LogoPair = () => {
    return (
        <div className="w-full flex items-center justify-evenly my-8 flex-col lg:flex-row">
            <img src={canvasLogo} className="w-1/2 h-12 lg:h-20 lg:w-auto" alt="Canvas LMS" />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
            </svg>
            <img src={logo} className="h-24 lg:h-30 w-auto" alt="Gamify" />
        </div>
    );
};

export default LogoPair;
