import { GAME_TYPES } from "../models/game";
import { GAME_PRIZES } from "../models/prize";
import { GAME_SECTIONS } from "./ui";

export const GAME_SECTION_ITEMS = [
    { label: GAME_SECTIONS.ACTIVE_GAMES },
    { label: GAME_SECTIONS.ARCHIVED_GAMES }
]

export const GAME_TYPE_ITEMS = [
    { label: GAME_TYPES.RACING_GAME }
]

export const GAME_PRIZE_ITEMS = [
    { label: GAME_PRIZES.TROPHY }
]