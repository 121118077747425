import { useEffect } from "react"
import { fetchCanvasCourses, fetchCanvasNewQuizzes, fetchCanvasQuizzes, fetchCanvasUserData } from "../redux/modules/canvas/actions"
import { useAppDispatch, useAppSelector } from "../redux/store"
import { isCourseFinished } from "../utils/course"
import useHandleEvent from "./useHandleEvent"


const useFetchCanvasCourses = () => {

    const dispatch = useAppDispatch()

    const { handleEvent, isLoading } = useHandleEvent()
    const { user } = useAppSelector(state => state.user)

    useEffect(() => {

        if (!user) return

        const fetchData = () => handleEvent(async () => {

            const [courses] = await Promise.all([
                dispatch(fetchCanvasCourses()),
                dispatch(fetchCanvasUserData())
            ])

            const promises: Promise<any>[] = []

            courses.forEach(course => {

                promises.push(
                    dispatch(fetchCanvasQuizzes(course.id.toString())),
                )

                if (!isCourseFinished(course)) {
                    dispatch(fetchCanvasNewQuizzes(course.id.toString()))
                }

            })

            await Promise.all(promises)

        })

        fetchData()

    }, [user])

    return {
        isLoading
    }

}

export default useFetchCanvasCourses