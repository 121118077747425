import { useAppSelector } from "../../../redux/store";
import GameDetailButtonArchive from "./ButtonArchive";
import GameDetailButtonStartGame from "./ButtonStartGame";
import GameDetailItems from './Items';
import GameDetailLogo from './Logo';

const GameDetail = () => {

    const { game, activeRooms } = useAppSelector((state) => state.game);
    const room = Object.keys(activeRooms).map(k => activeRooms[k])
        .find(e => e.game === game.id)

    const Title = () => (
        <h1 className="text-2xl font-bold text-gray-900 ">
            {game.name} {game.archived && '(Archived)'}
        </h1>
    )

    return (
        <div className="w-full">
            <section className="w-full lg:w-1/2">
                <GameDetailLogo />
                <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                        <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                            <Title />
                        </div>
                    </div>

                    <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
                        <Title />
                    </div>
                </div>
                <GameDetailButtonStartGame room={room} />
                <GameDetailItems />
            </section>
            <GameDetailButtonArchive />
        </div>
    );
};

export default GameDetail;
