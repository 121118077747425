import { useAppSelector } from "../../redux/store"
import UIGridList from "../UI/Grid/List"
import CourseCard from "./Card"

const CourseList = () => {

  const { courses } = useAppSelector(state => state.canvas)

  return (
    <UIGridList items={courses} component={CourseCard} />
  )
}

export default CourseList
