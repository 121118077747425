import { getStorage } from "@firebase/storage";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCZijTDFRzNWVDHZoyin6ZOm0Cg-xQBAx0",
    authDomain: "gamify-ed.firebaseapp.com",
    projectId: "gamify-ed",
    storageBucket: "gamify-ed.appspot.com",
    messagingSenderId: "73541682410",
    appId: "1:73541682410:web:e3bd52b25a6fdbe06f8393",
    measurementId: "G-B1NCE6P9GX"
}

export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
