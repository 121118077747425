const firebaseErrors = new Map();
firebaseErrors.set('auth/invalid-email', 'Enter a valid email');
firebaseErrors.set('auth/user-not-found', 'User not found');
firebaseErrors.set('auth/wrong-password', 'Incorrect password');
firebaseErrors.set(
    'auth/account-exists-with-different-credential',
    'An account already exists with a different sign-in method.'
);
firebaseErrors.set('auth/email-already-in-use', 'The email address is already in use');
firebaseErrors.set(
    'auth/network-request-failed',
    'Please check your internet connection'
);
firebaseErrors.set(
    'auth/weak-password',
    'The password is too weak, try a stronger one.'
);
firebaseErrors.set(
    'auth/too-many-requests',
    'Too many attempts, please wait 3 minutes.'
);
firebaseErrors.set(
    'auth/email-already-in-use',
    'The email address is already in use by another account'
);
firebaseErrors.set(
    'auth/provider-already-linked',
    'The phone number provided already exists, try another one'
);
firebaseErrors.set(
    'auth/invalid-phone-number',
    'The phone number has an invalid format.'
);
firebaseErrors.set('auth/captcha-check-failed', 'Invalid reCAPTCHA, please try again');
firebaseErrors.set(
    'auth/user-disabled',
    'The account has been disabled by the administrator'
);
firebaseErrors.set(
    'auth/invalid-verification-code',
    'The code is incorrect.'
);
firebaseErrors.set(
    'auth/email-already-exists',
    'The email address is already registered, try another one.'
);

export default firebaseErrors;
