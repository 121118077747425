import { FC } from 'react'


interface Props {
  title:string,
}

const Title :FC<Props> = ({title}) => {
  return (
    <>
      <h1 className=" text-[2rem] px-3 pb-2">
        {title}
      </h1>
    </>
  )
}

export default Title