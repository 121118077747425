import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo-small.png';
import NavbarItemsAuth from './ItemsAuth';
import { NAVBAR_ITEMS } from './constants';
import Navbaritem from './item';
import { ROUTES } from '../../constants/routes';

const Items = () => NAVBAR_ITEMS.map((link) => <Navbaritem {...link} key={link.name} />)

const Navbar = () => {

  return (
    <header className="bg-white">
      <nav className=" mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-accentYellow lg:border-none">
          <div className="flex items-center justify-between">
            <Link to={ROUTES.HOME}>
              <span className="sr-only">Workflow</span>
              <img className="h-10 w-auto" src={logo} alt="Gamify" />
            </Link>
          </div>
          <div className="ml-10 space-x-4 flex items-center">
            <div className="hidden ml-10 space-x-8 lg:block">
              <Items />
            </div>
            <NavbarItemsAuth />
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          <Items />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
