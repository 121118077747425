import { HeadTable } from "../components/UI/Table/props";

export const USERS_TABLE_HEAD: HeadTable = [
    {
        text: "Active",
        value: "active",
    },
    {
        text: "Name",
        value: "fullName",
    },
    {
        text: "Email",
        value: "email",
    },
    {
        text: "Role",
        value: "role",
    },
    {
        text: "Actions",
        value: "actions",
    }
]