
interface Props {
    title: string;
}

const QuizCardTitle = ({ title }: Props) => (
    <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div>
    </div>
);

export default QuizCardTitle;
