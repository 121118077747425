import { useEffect, useState } from "react"
import { subscribeToGamesChanges } from "../../../redux/modules/game/actions"
import { useAppDispatch } from "../../../redux/store"
import GameList from "../../../components/Game/List"
import UITabs from "../../../components/UI/Tabs"
import { GAME_SECTIONS } from "../../../constants/ui"
import { GAME_SECTION_ITEMS } from "../../../constants/game"
import Title from "../../../components/UI/Title/Title"

const DashboardGamesPage = () => {

    const [currentSection, setCurrentSection] = useState(GAME_SECTIONS.ACTIVE_GAMES)
    const dispatch = useAppDispatch()

    useEffect(() => {

        const unsubscribe = dispatch(subscribeToGamesChanges())

        return () => {
            unsubscribe()
        }

    }, [])

    return (
        <>
            <Title title="Games" />
            <UITabs items={GAME_SECTION_ITEMS}
                value={currentSection} onClick={e => setCurrentSection(e.label as GAME_SECTIONS)} />
            <div className="p-3">
                <GameList section={currentSection} />
            </div>
        </>
    )
}

export default DashboardGamesPage
