import { FC } from 'react';
import { Link } from 'react-router-dom';

interface Item {
    to: string;
    text: string;
}

interface AuthLinksProps {
    items: Item[];
}

const AuthLink: FC<Item> = ({ to, text }) => (
    <div className="text-sm">
        <Link
            to={to}
            className="font-medium text-gameBlue hover:text-opacity-75"
        >
            {text}
        </Link>
    </div>
);

const AuthLinks: FC<AuthLinksProps> = ({ items }) => (
    <div className="flex items-center justify-between">
        {items.map((item, index) => (
            <AuthLink key={index} {...item} />
        ))}
    </div>
);

export default AuthLinks;
