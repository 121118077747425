import { getCanvasCourses, getCanvasNewQuizzes, getCanvasQuizzes, getCanvasUserData } from '../../../services/canvas';
import { AppDispatch, RootState } from '../../store';
import { setCourses, setQuizInNewQuizzes, setQuizInQuizzes, setUserData } from './reducer';

export const fetchCanvasCourses = () => async (
    dispatch: AppDispatch,
    getState: () => RootState
) => {

    const user = getState().user.user

    if (!user) return []

    const data = await getCanvasCourses({
        accessToken: user.accessToken,
        schoolUrl: user.schoolUrl
    })

    dispatch(setCourses(data))

    return data
};

export const fetchCanvasUserData = () => async (
    dispatch: AppDispatch,
    getState: () => RootState
) => {

    const user = getState().user.user

    if (!user) return []

    const data = await getCanvasUserData({
        accessToken: user.accessToken,
        schoolUrl: user.schoolUrl
    })

    dispatch(setUserData(data))

    return data
};

export const fetchCanvasQuizzes = (courseId: string) => async (
    dispatch: AppDispatch,
    getState: () => RootState
) => {

    const { user } = getState().user

    if (!user) return []

    const data = await getCanvasQuizzes({ courseId, accessToken: user.accessToken, schoolUrl: user.schoolUrl })

    dispatch(setQuizInQuizzes({ quiz: data, courseId }))

    return data
};

export const fetchCanvasNewQuizzes = (courseId: string) => async (
    dispatch: AppDispatch,
    getState: () => RootState
) => {

    const { user } = getState().user

    if (!user) return []

    const data = await getCanvasNewQuizzes({ courseId, accessToken: user.accessToken, schoolUrl: user.schoolUrl })

    dispatch(setQuizInNewQuizzes({ quiz: data, courseId }))

    return data
};