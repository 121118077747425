import firebaseApi from "../api/firebaseApi"
import { CourseI } from "../models/canvas/course"
import { NewQuizItem } from "../models/canvas/newItem"
import { NewQuizI } from "../models/canvas/newQuiz"
import { QuizQuestionI } from "../models/canvas/question"
import { QuizI } from "../models/canvas/quiz"
import { UserDataI } from "../models/canvas/userData"

export const getCanvasCourses = async (params: { accessToken: string, schoolUrl: string }) => {

    const courses = await firebaseApi.get<CourseI[]>('/getCourses', { params })

    return courses.filter(e => {
        if(e.end_at) {
            //@ts-ignore
            return new Date() < new Date(e.end_at)
        }
        return true
    })

}

export const getCanvasUserData = async (params: { accessToken: string, schoolUrl: string }) => {
    return await firebaseApi.get<UserDataI>('/getUserData', { params })
}

export const getCanvasQuizzes = async (params: { accessToken: string, schoolUrl: string, courseId: string }) => {
    return await firebaseApi.get<QuizI[]>('/getQuizzes', { params })
}

export const getCanvasNewQuizzes = async (params: { accessToken: string, schoolUrl: string, courseId: string }) => {
    return await firebaseApi.get<NewQuizI[]>('/getNewQuizzes', { params })
}

export const getCanvasQuizQuestions = async (params: { accessToken: string, schoolUrl: string, courseId: string, quizId: string, numberOfQuestions: string }) => {
    return await firebaseApi.get<QuizQuestionI[]>('/getQuizQuestions', { params })
}

export const getCanvasNewQuizItems = async (params: { accessToken: string, schoolUrl: string, courseId: string, quizId: string, numberOfQuestions: string }) => {
    return await firebaseApi.get<NewQuizItem[]>('/getNewQuizQuestions', { params })
}