import CourseList from "../../components/Course/List"
import Loading from "../../components/Loading"
import Title from "../../components/UI/Title/Title"
import useFetchCanvasCourses from "../../hooks/useFetchCanvasCourses"

const DashboardPage = () => {

    const { isLoading } = useFetchCanvasCourses()

    if (isLoading) return <Loading />

    return (
        <div>
            <Title title="Home" />
            <CourseList />
        </div>
    )
}

export default DashboardPage
