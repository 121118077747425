// import { useDispatch } from 'react-redux';

import { useState } from "react";
import { useParams } from "react-router-dom";
import useHandleEvent from "../../../hooks/useHandleEvent";
import useModal from "../../../hooks/useModal";
import { QuizQuestionI } from "../../../models/canvas/question";
import { QuizI } from "../../../models/canvas/quiz";
import { useAppSelector } from "../../../redux/store";
import { getCanvasNewQuizItems, getCanvasQuizQuestions } from "../../../services/canvas";
import GameForm from "../../Game/Form";
import Loading from "../../Loading";
import UIModal from "../../UI/Modal";
import QuizCardStatusBadge from "./StatusBadge";
import QuizCardTitle from "./Title";
import { NewQuizI } from "../../../models/canvas/newQuiz";
import { NewQuizItem } from "../../../models/canvas/newItem";

interface Props {
    item: QuizI | NewQuizI
    courseName?:string
}

const Item = ({ text }: { text: string }) => (
    <div className="px-6 pt-4 pb-2">
        <span className="inline-block bg-backgroundAccent rounded-full px-3 py-1 text-sm font-semibold text-gameBlue mr-2 mb-2">
            {text}
        </span>
    </div>
)

const QuizCard = ({ item, courseName }: Props) => {

    const { showModal, isModalVisible, hideModal } = useModal()
    const [quizQuestions, setQuizQuestions] = useState<QuizQuestionI[] | NewQuizItem[]>([])
    const { user } = useAppSelector(state => state.user)
    const { courseId } = useParams()
    const { isLoading, handleEvent } = useHandleEvent()
    const handleClick = () => handleEvent(async () => {

        const payload = {
            schoolUrl: user.schoolUrl,
            accessToken: user.accessToken,
            quizId: item.id.toString(),
            numberOfQuestions: 'question_count' in item ? item.question_count.toString() : '10',
            courseId: String(courseId),
            type: item.type,
        }

        const data = item.type === "new-quiz" ? await getCanvasNewQuizItems(payload)
            : await getCanvasQuizQuestions(payload)
        setQuizQuestions(data)
        showModal()
    })

    // const dispatch = useDispatch();

    return (
        <>
            {isLoading && <Loading />}
            {isModalVisible && <UIModal onClose={hideModal}>
                <GameForm quiz={item} quizQuestions={quizQuestions} courseName={courseName}/>
            </UIModal>
            }
            <div
                onClick={handleClick}
                className={`${item.published ? 'cursor-pointer' : 'cursor-not-allowed'}`}>
                <div className="max-w-sm rounded overflow-hidden ">
                    <QuizCardTitle title={item.title} />
                    {'question_count' in item && <Item text={`${item.question_count} questions found in quiz`} />}
                    {item.type === "new-quiz" && <Item text="New quiz version" />}
                    <div className="px-6 pt-4 pb-2">
                        <QuizCardStatusBadge isPublished={item.published} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuizCard;
