
interface Props {
    isPublished: boolean;
}

const QuizCardStatusBadge = ({ isPublished }:Props) => (
    isPublished ? (
        <span className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-green-700 mr-2 mb-2">
            Published
        </span>
    ) : (
        <span className="inline-block bg-red-200 rounded-full px-3 py-1 text-sm font-semibold text-red-700 mr-2 mb-2">
            Not Published
        </span>
    )
);

export default QuizCardStatusBadge;
