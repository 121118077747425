import { FC } from "react"
import { UISelectProps } from "./props"
import { ItemI } from "../../../models/item"

const Item = ({ item }: { item: ItemI }) => {
    return (
        <option value={item.value} >
            {item.label}
        </option>
    )
}

const UISelect: FC<UISelectProps> = (props) => {

    const {
        items, name, onChange, className = "mt-1 sm:mt-0 sm:col-span-2 w-full",
        placeholder = "Select an option", label,
        ...last
    } = props


    return (
        <>
            {label && <label htmlFor={name} className="sr-only">{label}</label>}
            <div className={className}>
                <select className="w-full flex justify-center py-2  items-center  outline-none focus:outline-none border-fade border-gameBlue border-2 rounded-3xl my-4"
                    id={name} name={name} onChange={onChange} {...last} >
                    <option defaultValue='' value='' className="text-md">{placeholder}</option>
                    {
                        items?.map((e, i) => <Item key={i} item={e} />)
                    }
                </select>
            </div>
        </>
    )
}

export default UISelect