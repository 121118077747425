
interface Props {
    title: string
    courseName?: string
    numberOfQuestions: number
}

const GameFormQuizAbstract = ({ title, numberOfQuestions, courseName }: Props) => {
    return (
        <div className="w-full bg-gray-200 p-2 rounded-xl">
            <div className="text-center p-2">Course: {courseName}</div>
            <div className="text-center p-2">Survey: {title}</div>
            <div className="text-center p-2">Questions available: {numberOfQuestions}</div>
        </div>
    )
}

export default GameFormQuizAbstract
