import { CourseI } from "../models/canvas/course"
import { isDateFinished } from "./dates"

export const isCourseFinished = (course: CourseI) => {

    if (course.end_at === null) {
        return false
    }

    return isDateFinished(course.end_at)

}

export const getCourseName = (course:CourseI) => {
    return course.original_name || course.name
}