import { Dot } from './Dot'

export const Dots = () => {
    return (
        <>
            <Dot className=' bg-blue-300 ' />
            <Dot className=' bg-yellow-200' />
            <Dot className=' bg-pink-200' />
        </>
    )
}
