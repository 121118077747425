import { useMemo, useState } from "react"
import { USERS_TABLE_HEAD } from "../../../constants/users"
import useModal from "../../../hooks/useModal"
import { UserDBI } from "../../../models/user"
import { useAppSelector } from "../../../redux/store"
import IconPencil from "../../Icon/Pencil"
import ModalUser from "../../Modal/User"
import { UITable } from "../../UI/Table"
import UserTableIconProhibited from "./IconProhibited"
import UserTableIconDelete from "./Delete"
import { isActiveUser } from "../../../utils/user"

const UserTable = () => {

    const { users } = useAppSelector(state => state.user)
    const { isModalVisible, showModal, hideModal } = useModal()
    const [currentUser, setCurrentUser] = useState<UserDBI | undefined>(undefined)

    const handleEdit = (e: UserDBI) => {
        setCurrentUser(e)
        showModal()
    }

    const body = users?.map(e => ({
        fullName: e.fullName,
        email: e.email,
        active: e.active === undefined ? 'Active' : e.active ? 'Active' : 'Inactive',
        role: e.role,
        actions: (
            <div className="flex gap-3 items-center">
                <IconPencil width="15" height="15" onClick={() => {
                    handleEdit(e)
                }} />
                <UserTableIconProhibited user={e} />
                {!isActiveUser(e.active) && <UserTableIconDelete user={e} />}
            </div>
        ),
    }))

    return (
        <>
            {isModalVisible && <ModalUser onClose={hideModal} user={currentUser} />}
            {useMemo(() => <UITable body={body || []} head={USERS_TABLE_HEAD} />, [body])}
        </>
    )

}

export default UserTable