import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../redux/store"
import { signOutUser } from "../../redux/modules/auth/actions"
import { ROUTES } from "../../constants/routes"

const Logout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  
  return (
    <div onClick={async () => {
        await dispatch(signOutUser())
        navigate(ROUTES.SIGN_IN)

    }}
        className='text-gray-600 cursor-pointer hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md'>
        Logout
    </div>
  )
}

export default Logout