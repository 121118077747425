import { sendPasswordResetEmail } from "firebase/auth"
import AuthLayout from "../components/Auth/Layout"
import useAuthForm from "../components/Auth/useAuthForm"
import UIInput from "../components/UI/Input"
import { auth } from "../firebase"
import { showToastSuccess } from "../utils/toast"

const RecoverPasswordPage = () => {

    const {
        isLoading, onChange,
        email, handleEvent
    } = useAuthForm()

    const onSubmit = () => handleEvent(async () => {
        await sendPasswordResetEmail(auth, email)
        showToastSuccess("Email has been sent")
    })

    return (
        <AuthLayout title="Recover password on Gamify" buttonText="Send recovery email"
            onSubmit={onSubmit} isLoading={isLoading}>
            <UIInput placeholder="account@gamify.com" name="email" onChange={onChange}
                required type="email" />
        </AuthLayout>
    )
}

export default RecoverPasswordPage