import { GAME_PRIZES } from "./prize"
import { QuestionI } from "./question"

export const enum GAME_TYPES {
    RACING_GAME="Racing Game"
}

export interface GameI {
    courseId:string
    coverImage:string
    gameType:GAME_TYPES
    name:string
    owner:string
    prize:GAME_PRIZES
    quizId:number
    questions:QuestionI[]
    durationBetweenAnswers:number
    durationBetweenQuestions:number
    rangeMessages?:RangeMessagesI
    id:string
    archived:boolean
}

export type RangeMessagesI = RangeMessageI[]

export interface RangeMessageI {
    min:number,
    max:number,
    message:string
}