import { IconProps } from './props';

const IconDelete = ({
    onClick,
    width = '24',
    height = '24',
    color = 'black',
    disabled,
}: IconProps) => {
    return (
        <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={!disabled ? color : 'gray'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-trash-2"
            style={{ cursor: 'pointer' }}
        >
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6l-1.35 14.27A2 2 0 0 1 15.65 22H8.35a2 2 0 0 1-1.99-1.73L5 6m5-1V4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v1"></path>
            <line x1="10" y1="11" x2="10" y2="17"></line>
            <line x1="14" y1="11" x2="14" y2="17"></line>
        </svg>
    );
};

export default IconDelete;
