import { useState } from "react";
import { useAppSelector } from "../../redux/store"
import Logout from "../Sidebar/logout";

const HeaderUserImage = () => {

    const {userData} = useAppSelector(state=>state.canvas)
    const [menuVisible, setMenuVisible] = useState(false);

    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
      };

    return (
        <div className="relative">
            <img className="h-8 w-8 rounded-full cursor-pointer" onClick={toggleMenu}  src={userData?.avatar_url} alt="" />
            {menuVisible && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
                    <ul>
                        <li className="px-4 py-2 hr:bg-gray-100 cursor-pointer">
                            <Logout />
                        </li>
                    </ul>
                </div>
            )}
        </div>
    )
}

export default HeaderUserImage
