import { HeadTable } from "./props"

export const UITableHead = ({ head }: { head: HeadTable }) => {
    return (
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
                {head.map((item, i) => <TableHeadItem item={item} key={i} />)}
            </tr>
        </thead>
    )
}

const TableHeadItem = ({ item }: { item: any }) => {
    return (
        <th className="px-6 py-3">
            {item.text}
        </th>
    )
}