import LogoPair from "../LogoPair"

const HomeWelcome = () => {
    return (
        <>
            <LogoPair />
            <div className="w-full justify-center items-center flex p-4 text-center">
                <h1 className="text-5xl font-bold">Welcome to Gamify</h1>
            </div>
        </>
    )
}

export default HomeWelcome
