import { useEffect, useState } from "react";

interface KeyPressI {
    key: string
}

const useKeyPress = (targetKey: string) => {

    const [keyPressed, setKeyPressed] = useState<boolean>(false);

    const onPressKey = ({ key }: KeyPressI) => {
        if (key !== targetKey) return
        setKeyPressed(true)
    }

    const onReleaseKey = ({ key }: KeyPressI) => {
        if (key !== targetKey) return
        setKeyPressed(false)
    };

    useEffect(() => {
        
        window.addEventListener("keydown", onPressKey)
        window.addEventListener("keyup", onReleaseKey)

        return () => {
            window.removeEventListener("keydown", onPressKey)
            window.removeEventListener("keyup", onReleaseKey)
        }

    }, [])

    return keyPressed

}

export default useKeyPress