import { useState } from "react"
import HomeWelcome from "../components/Home/Welcome"
import UIButton from "../components/UI/Button"
import HomeSteps from "../components/Home/Steps"
import { ROUTES } from "../constants/routes"
import { useAppSelector } from "../redux/store"
import { Navigate } from "react-router-dom"

const enum SECTIONS {
    WELCOME = "Welcome",
    STEPS = "Steps"
}

const HomePage = () => {

    const [currentSection, setCurrentSection] = useState(SECTIONS.WELCOME)

    const {user} = useAppSelector(state=>state.user)

    if(user) return <Navigate to={ROUTES.DASHBOARD_COURSES} replace />

    if(!user) return <Navigate to={ROUTES.SIGN_IN} replace />

    return (
        <div className="max-w-6xl mx-auto flex justify-center items-center flex-col flex-grow p-4">
            {currentSection === SECTIONS.WELCOME && <>
                <HomeWelcome />
                <UIButton width="1/2" onClick={() => setCurrentSection(SECTIONS.STEPS)}>
                    Go
                </UIButton>
            </>}
            {currentSection === SECTIONS.STEPS && <>
                <HomeSteps />
                <UIButton width="1/2" link={ROUTES.SIGN_UP}>
                    Get Started
                </UIButton>
            </>}
        </div>
    )
}

export default HomePage
