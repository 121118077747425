import { Link } from 'react-router-dom';
import gamifyLogo from '../../assets/img/logo-big.png';
import { GameI } from '../../models/game';
import { ROUTES } from '../../constants/routes';
// import { setSelectedGame } from '../../../../redux/modules/games/actions';

interface Props {
    item: GameI
}

const ItemBadge = ({ text, className }: { text: string, className: string }) => (
    <span className={`inline-block rounded-full px-3 py-1 mr-2 mb-2 ${className}`}>
        {text}
    </span>
)

const GameCard = ({ item }: Props) => {
    //   const dispatch = useDispatch();
    const imageUrl = item.coverImage || gamifyLogo;
    const imageClasses = `w-full max-h-48 object-cover ${item.archived ? 'grayscale' : ''}`;

    return (
        <Link to={`${ROUTES.DASHBOARD_GAMES}/${item.id}`} onClick={() => {
            // dispatch(setSelectedGame(item))
        }}>
            <div className="w-full rounded overflow-hidden">
                <img src={imageUrl} alt={item.name} className={imageClasses} />
                <div className="px-6 py-4">
                    <div className="font-bold text-xl mb-2">
                        {item.name} <span className="text-xs">{item.archived && '(Archived)'}</span>
                    </div>
                </div>
                <div className="px-6 pt-4 pb-2 text-sm font-semibold">
                    <ItemBadge className='bg-gray-200 text-gray-700' text={`${item.questions?.length || 0} questions`} />
                    <ItemBadge className='bg-gameBlue text-white' text={item.gameType} />
                    <ItemBadge className='bg-gray-200 text-gray-700' text={item.prize} />
                </div>
            </div>
        </Link>
    );
};

export default GameCard;
