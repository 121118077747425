import { collection, doc, onSnapshot, query, where } from 'firebase/firestore';
import { FS_FIELDS } from '../../../constants/firebase';
import { db } from '../../../firebase';
import { UserRoleI } from '../../../models/role';
import { UserDBI } from '../../../models/user';
import { updateUser as updateUserDB } from '../../../services/user';
import { AppDispatch, RootState } from '../../store';
import { setUser, setUsers } from './reducer';

export const subscribeToUserChanges = (id: string) => (
  dispatch: AppDispatch,
) => {

  const unsub = onSnapshot(doc(db, FS_FIELDS.USERS, id), (snap) => {

    if (!snap.exists()) throw `user does not existes ${id}`

    dispatch(setUser(snap.data() as UserDBI))

  });
  return unsub
};

export const subscribeToUsersChanges = () => (dispatch: AppDispatch) => {

  const role: UserRoleI = "Admin"

  const q = query(
    collection(db, FS_FIELDS.USERS), 
    where("role", "!=", role),
  )

  const unsub = onSnapshot(q, (snapshot) => {
    const users: UserDBI[] = [];
    snapshot.forEach((doc) => {
      users.push(doc.data() as UserDBI);
    });
    dispatch(setUsers(users));
  });

  return unsub
};

export const updateUser = (payload: any) => async (
  _: AppDispatch,
  getState: () => RootState
) => {

  const { user } = getState().auth

  return await updateUserDB(user.uid, payload)

}