import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { CanvasState } from "./types";
import { CourseI } from '../../../models/canvas/course';
import { UserDataI } from '../../../models/canvas/userData';
import { QuizI } from '../../../models/canvas/quiz';
import { NewQuizI } from '../../../models/canvas/newQuiz';

const initialState: CanvasState = {
    courses: [],
    userData: undefined as any,
    quizzes: {},
    newQuizzes: {},
}

const CanvasSlice = createSlice({
    name: 'canvas',
    initialState,
    reducers: {
        setCourses: (state, action: PayloadAction<CourseI[]>) => {
            state.courses = action.payload;
        },
        setQuizzes: (state, action: PayloadAction<{ [k: string]: QuizI[] }>) => {
            state.quizzes = action.payload
        },
        setQuizInQuizzes: (state, action: PayloadAction<{ quiz: QuizI[], courseId: string }>) => {
            state.quizzes = {
                ...state.quizzes,
                [action.payload.courseId]: action.payload.quiz,
            }
        },
        setQuizInNewQuizzes: (state, action: PayloadAction<{ quiz: NewQuizI[], courseId: string }>) => {
            state.newQuizzes = {
                ...state.newQuizzes,
                [action.payload.courseId]: action.payload.quiz,
            }
        },
        setNewQuizzes: (state, action: PayloadAction<{ [k: string]: NewQuizI[] }>) => {
            state.newQuizzes = action.payload
        },
        setUserData: (state, action: PayloadAction<UserDataI>) => {
            state.userData = action.payload
        }
    },
})

export const {
    setCourses, setUserData,
    setQuizInQuizzes, setQuizInNewQuizzes,
    setNewQuizzes, setQuizzes,
} = CanvasSlice.actions;

export default CanvasSlice.reducer