import { useEffect } from "react"
import UserTable from "../../components/User/Table"
import { subscribeToUsersChanges } from "../../redux/modules/user/actions"
import { useAppDispatch } from "../../redux/store"

const DashboardUsersPage = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        const unsubscribe = dispatch(subscribeToUsersChanges())

        return () => {
            unsubscribe()
        }

    }, [])


    return (
        <div className="">
            <UserTable />
        </div>
    )
}

export default DashboardUsersPage
