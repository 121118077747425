import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { subscribeToActiveRoomsChanges, subscribeToGameChanges } from "../../../redux/modules/game/actions"
import { useAppDispatch, useAppSelector } from "../../../redux/store"
import GameDetail from "../../../components/Game/Detail"
import { fetchCanvasCourses } from "../../../redux/modules/canvas/actions"

const DashboardGamesIdPage = () => {

    const dispatch = useAppDispatch()
    const { gameId } = useParams()
    const {game} = useAppSelector(state=>state.game)

    useEffect(() => {

        const unsubscribeGame = dispatch(subscribeToGameChanges(String(gameId)))
        const unsubscribeRooms = dispatch(subscribeToActiveRoomsChanges())
        
        dispatch(fetchCanvasCourses())

        return () => {
            unsubscribeGame()
            unsubscribeRooms()
        }

    }, [])

    if(!game) return null

    return (
        <>
            <GameDetail />
        </>
    )
}

export default DashboardGamesIdPage
