import { UIPaginationArrow } from './Arrow';
import { UIPaginationItem } from './Item';
import { DOTS, usePagination } from './usePagination';

interface PropsUIPagination {
    onPageChange: (n: number) => void,
    totalCount: number,
    siblingCount?: number,
    currentPage: number,
    pageSize: number,
}

export const UIPagination = (props: PropsUIPagination) => {

    const { onPageChange, currentPage } = props

    const paginationRange = usePagination(props);

    if (!paginationRange) return <div>pagination range undefined</div>

    const paginationSize = paginationRange.length

    if (currentPage === 0 || paginationSize < 2) return null

    const onNext = () => {
        if (currentPage === paginationSize) return
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        if (currentPage === 1) return
        onPageChange(currentPage - 1);
    };

    const isActive = (pageNumber: number) => pageNumber == currentPage

    return (
        <ul className='inline-flex items-center -space-x-px'>
            <UIPaginationArrow className="rounded-l-lg" onClick={() => { onPrevious() }}
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" />
            {paginationRange.map((pageNumber, i) => {

                if (pageNumber === DOTS) return null

                return (
                    <UIPaginationItem index={Number(pageNumber)} active={isActive(Number(pageNumber))}
                        onPageChange={onPageChange} key={i} />
                )
            })}
            <UIPaginationArrow className="rounded-r-lg" onClick={() => onNext()}
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" />
        </ul>
    );
};