import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './modules/auth/reducer';
import userReducer from './modules/user/reducer';
import canvasReducer from './modules/canvas/reducer';
import gameReducer from './modules/game/reducer';
import searchReducer from './modules/search/reducer';

const combinedReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  canvas:canvasReducer,
  game:gameReducer,
  search: searchReducer,
});

type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store['store']['dispatch'];
export type RootState = ReturnType<Store['store']['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const makeStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
  const persistor = persistStore(store);
  return { store, persistor };
};

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const wrapper = makeStore;
