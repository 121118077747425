import { Outlet } from "react-router-dom"
import Navbar from "../components/Navbar"

const HomeLayout = () => {
    return (
        <div className="flex flex-col h-screen">
            <Navbar />
            <Outlet />
        </div>
    )
}

export default HomeLayout
