import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Search } from './types';

const initialState: Search = {
  search: undefined as any,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    clearSearch: (state) => {
      state.search = '';
    },
  },
})

export const { setSearch, clearSearch } = searchSlice.actions;

export default searchSlice.reducer