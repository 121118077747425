import { useEffect, useMemo } from "react"
import { Outlet } from "react-router-dom"
import Header from "../components/Header"
import Sidebar from "../components/Sidebar"
import { useAppSelector } from "../redux/store"
import { updateUser } from "../services/user"

const DashboardLayout = () => {

    const { userData } = useAppSelector(state => state.canvas)
    const { user } = useAppSelector(state => state.user)

    useEffect(() => {

        if (!userData?.name) return
        if (!user) return
        if (user.fullName) return

        const handleUpdateUserName = async () => {

            await updateUser(user.uid, { fullName: userData.name })

        }

        handleUpdateUserName()

    }, [userData])

    return (
        <>
            {useMemo(() => (
                <div className="flex flex-col h-screen">
                    <Sidebar />
                    <div className="md:pl-64 flex flex-col flex-1">
                        <Header />
                        <main className="flex-1 py-6">
                            <Outlet />
                        </main>
                    </div>

                </div>
            ), [])}
        </>
    )
}

export default DashboardLayout
