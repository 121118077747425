import { ReactNode } from "react"
import LogoPair from "../LogoPair"
import UIButton from "../UI/Button"

interface Props {
    children: ReactNode
    title: string
    buttonText: string
    onSubmit?: () => void,
    isLoading?: boolean,
    titleClassName?: string
}

const AuthLayout = ({
    children, title, buttonText, onSubmit,
    isLoading, titleClassName = "text-3xl font-bold"
}: Props) => {
    return (
        <div className="max-w-6xl mx-auto flex justify-center items-center flex-col p-4">
            <LogoPair />
            <div className="w-full justify-center items-center flex p-4 text-center">
                <h1 className={titleClassName}>{title}</h1>
            </div>
            <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 w-full">
                <form className="max-w-6xl w-full space-y-8" onSubmit={e => {
                    e.preventDefault()
                    onSubmit && onSubmit()
                }}>
                    {children}
                    <UIButton
                        type="submit"
                        isLoading={isLoading}
                        width="w-full"
                    >
                        {buttonText}
                    </UIButton>
                </form>
            </div>
        </div>
    )
}

export default AuthLayout
