import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = ({courseName = ""}) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  
  if (pathnames.length < 3) {
    return null;
  }

  const basePath = `/${pathnames.slice(0, 2).join('/')}`;

  return (
    <nav className="pb-4">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <ol className="flex items-center space-x-2 text-sm">
          <li>
            <Link to={basePath}  className="text-blue-500 hover:underline capitalize">
              {pathnames[1]}
            </Link>
          </li>
          <li>
            <span className="mx-2">{'/'}</span>
            <span className="font-medium">{courseName}</span>
          </li>
        </ol>
      </div>
    </nav>
  );

};

export default Breadcrumb;