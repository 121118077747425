import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore"
import { FS_FIELDS } from "../constants/firebase"
import { db } from "../firebase"
import { UserDBI } from "../models/user"
import firebaseApi from "../api/firebaseApi"

export const getUser = async (userId: string) => {
    const snap = await getDoc(
        doc(db, FS_FIELDS.USERS, userId)
    )

    return snap.data() as UserDBI
}

export const createUser = async (uid: string, payload: any) => {

    return await setDoc(doc(db, FS_FIELDS.USERS, uid), {
        uid,
        ...payload,
        createdAt: new Date()
    });
}

export const updateUser = async (uid: string, payload: any) => {

    await updateDoc(doc(db, FS_FIELDS.USERS, uid), payload);
}

export const updateUserStatus = async ({userId,active}: { userId: string, active: boolean }) => {
    return await firebaseApi.post(`/updateUserStatus?userId=${userId}&active=${active}`,{})
}

export const deleteUser = async (userId:string) => {
    return await firebaseApi.post(`/deleteUser?userId=${userId}`,{})
}