import LogoPair from "../LogoPair";

const steps = [
    "Create your Gamify account",
    "Login to Canvas",
    "Link to your account",
    "Choose a Quiz to gamify",
    "Decide your options",
    "Share the game and start"
];

const HomeSteps = () => {
    return (
        <>
            <LogoPair />
            <div className="w-full justify-center items-center flex p-4 text-center">
                <h2 className="text-xl">
                    Welcome to Gamify - the fast and easy way to Gamify your quizzes
                </h2>
            </div>
            <ol className="list-decimal flex flex-col justify-center p-4">
                {steps.map((step, index) => (
                    <li key={index} className="text-2xl font-bold p-2">
                        {step}
                    </li>
                ))}
            </ol>
        </>
    );
}

export default HomeSteps;
