import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { FS_FIELDS } from "../../../constants/firebase";
import { db } from "../../../firebase";
import { GameI } from "../../../models/game";
import { AppDispatch, RootState } from "../../store";
import { setActiveRooms, setGame, setGames, setRoom } from "./reducer"; // Asume que tienes una acción para establecer varios juegos
import { RoomI } from "../../../models/room";

export const subscribeToGamesChanges = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const { user } = getState().auth
    const q = query(collection(db, FS_FIELDS.GAMES), where('owner', '==', user.uid))

    const unsubscribe = onSnapshot(q, (querySnapshot) => {

        const items: GameI[] = [];

        querySnapshot.forEach((docSnapshot) => {
            if (docSnapshot.exists()) {
                const gameData = docSnapshot.data() as GameI;
                items.push({
                    ...gameData,
                    id: docSnapshot.id,
                });
            }
        });

        dispatch(setGames(items))
    });

    return unsubscribe;
};

export const subscribeToGameChanges = (gameId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    
    const unsubscribe = onSnapshot(doc(db, FS_FIELDS.GAMES, gameId), (docSnapshot) => {

        if (!docSnapshot.exists()) return

        const newData = docSnapshot.data() as GameI

        if (newData !== getState().game.game) {
            dispatch(setGame({
                ...newData,
                id: docSnapshot.id,
            }))
        }

    });

    return unsubscribe;
};

export const subscribeToRoomChanges = (roomId: string) => (dispatch: AppDispatch, getState: () => RootState) => {

    const unsubscribe = onSnapshot(doc(db, FS_FIELDS.ROOMS, roomId), (docSnapshot) => {

        if (!docSnapshot.exists()) return

        const newData = docSnapshot.data() as RoomI

        if (newData !== getState().game.room) {
            dispatch(setRoom({
                ...newData,
                id: docSnapshot.id,
            }))
        }

    });

    return unsubscribe;
};

export const subscribeToActiveRoomsChanges = () => (dispatch: AppDispatch, getState: () => RootState) => {

    const { user } = getState().auth
    const q = query(
        collection(db, FS_FIELDS.ROOMS),
        where('owner', '==', user.uid),
        where("active", "==", true),
        // where("archived", "==", false),
    )

    const unsubscribe = onSnapshot(q, (querySnapshot) => {

        const items: { [k: string]: RoomI } = {};

        querySnapshot.forEach((snap) => {
            if (snap.exists()) {
                const data = snap.data() as RoomI;
                items[snap.id] = { ...data, id: snap.id }
            }
        });

        dispatch(setActiveRooms(items))
    });

    return unsubscribe;
};