import { IconProps } from './props';

const IconPencil = ({
    onClick,
    width = '24',
    height = '24',
    color = 'black',
}: IconProps) => {
    return (
        <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            //   strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-edit-2"
            style={{ cursor: 'pointer' }}
        >
            <path d="M17 2.5c.4 0 .8.2 1.1.4l2 2c.5.6.5 1.5 0 2.1l-8.4 8.4-3.6.9c-1.2.3-2.3-.9-1.9-2.1l.9-3.6 8.4-8.4c.2-.2.6-.4 1-.4zm0-1.5c-.8 0-1.6.3-2.1.9l-8.4 8.4c-.3.3-.4.7-.4 1.1l-1 4.1c-.4 1.8 1.1 3.3 2.9 2.9l4.1-1c.4-.1.8-.3 1.1-.4l8.4-8.4c1.1-1.1 1.1-2.9 0-4l-2-2c-.5-.5-1.2-.8-1.9-.8zM2 20h16v2H2z"></path>
        </svg>
    );
};

export default IconPencil;
