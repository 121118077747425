import { IconProps } from './props';

const IconProhibited = ({
    onClick,
    width = '24',
    height = '24',
    color = 'black',
    disabled,
}: IconProps) => {
    return (
        <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={!disabled ? color : 'gray'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-prohibited"
            style={{ cursor: 'pointer' }}
        >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="4.93" y1="4.93" x2="19.07" y2="19.07"></line>
        </svg>
    );
};

export default IconProhibited;
