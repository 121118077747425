import { FC } from 'react'

interface PropsArrow {
    d: string,
    className?: string,
    onClick: () => void,
}

export const UIPaginationArrow: FC<PropsArrow> = ({ d, className, onClick }) => {

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        onClick()
    }

    return (
        <li>
            <a onClick={handleClick} href="" className={`block sm:py-2 sm:px-3 py-5 px-5 ml-0 leading-ri text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${className}`}>
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d={d} clipRule="evenodd"></path>
                </svg>
            </a>
        </li>
    )
}