import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { storage } from "../firebase"

export const uploadFile = async (file: File, path: string) => {

    const storageRef = ref(storage, path)

    await uploadBytes(storageRef, file as Blob)
    const url = await getDownloadURL(ref(storage, path))

    return {
        path,
        url
    }

}