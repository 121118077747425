import UseForm from "../../hooks/useForm";
import useHandleEvent from "../../hooks/useHandleEvent";


const useAuthForm = () => {

  const {
    formData: { email, password },
    setFormData,
    onChange,
  } = UseForm({ email: "", password: "" })
  const { isLoading, handleEvent } = useHandleEvent()

  return {
    isLoading,
    setFormData,
    email,
    password,
    handleEvent,
    onChange,
  }

}

export default useAuthForm