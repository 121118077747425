import useHandleEvent from "../../../hooks/useHandleEvent"
import useModal from "../../../hooks/useModal"
import { UserDBI } from "../../../models/user"
import { deleteUser } from "../../../services/user"
import { showToastSuccess } from "../../../utils/toast"
import IconDelete from "../../Icon/Delete"
import UIConfirmDialog from "../../UI/ConfirmDialog"

const UserTableIconDelete = ({ user }: { user: UserDBI }) => {

    const { handleEvent, isLoading } = useHandleEvent()
    const { isModalVisible, hideModal, showModal } = useModal()

    const handleDelete = () => handleEvent(async () => {
        hideModal()
        await deleteUser(user.uid)
        showToastSuccess(`The user has been deleted`)
    })

    return (
        <>
            <IconDelete width="15" height="15" onClick={showModal} disabled={isLoading} />
            <UIConfirmDialog isOpen={isModalVisible} onCancel={hideModal}
                onConfirm={handleDelete} message={`The user ${user.email} will be deleted`} />
        </>
    )
}

export default UserTableIconDelete
