import { addDoc, arrayUnion, collection, doc, updateDoc } from "firebase/firestore";
import { FS_FIELDS } from "../constants/firebase";
import { db } from "../firebase";
import { GameI } from "../models/game";
import { generatePinCode } from "../utils/strings";
import { updateGame } from "./game";
import { CourseI } from "../models/canvas/course";
import { getCourseName } from "../utils/course";

export const updateRoom = async (roomId: string, data: any) => {
    return await updateDoc(doc(db, FS_FIELDS.ROOMS, roomId), data);

}

export const createRoom = async (data: any) => {
    return await addDoc(collection(db, FS_FIELDS.ROOMS), data)
}

export const createNewGameRoom = async ({
    game, course
}: { game: GameI, course: CourseI }) => {

    const data = {
        game: game.id,
        owner: game.owner,
        active: true,
        archived: false,
        answers: {},
        scores: {},
        allPlayers: [],
        colors: {},
        started: false,
        startDate: '',
        endDate: '',
        pinCode: generatePinCode(),
        courseName: getCourseName(course),
    }

    const roomRef = await createRoom(data)

    await updateGame(game.id, {
        rooms: arrayUnion(roomRef.id)
    })

    return roomRef

}