import { useNavigate } from "react-router-dom"
import AuthLayout from "../components/Auth/Layout"
import UIInput from "../components/UI/Input"
import { ROUTES } from "../constants/routes"
import UseForm from "../hooks/useForm"
import useHandleEvent from "../hooks/useHandleEvent"
import { useAppSelector } from "../redux/store"
import { updateUser } from "../services/user"

const NewUserPage = () => {

    const {
        formData,
        onChange,
    } = UseForm({
        accessToken: "",
        schoolUrl: "https://mec.instructure.com/",
    })
    const { isLoading, handleEvent } = useHandleEvent()
    const { user } = useAppSelector(state => state.auth)
    const navigate = useNavigate()

    const onSubmit = () => handleEvent(async () => {
        await updateUser(user.uid, formData)
        navigate(ROUTES.DASHBOARD_COURSES)
    })

    return (
        <AuthLayout title="Connect your school and your account Canvas account" buttonText="Save"
            onSubmit={onSubmit} isLoading={isLoading} titleClassName="font-bold text-xl">
            <UIInput placeholder="https://school.instructure.com/" name="schoolUrl" onChange={onChange}
                width="w-full" type="url" required value={formData.schoolUrl} />
            <UIInput placeholder="Your access token" name="accessToken" onChange={onChange}
                width="w-full" required />
        </AuthLayout>
    )
}

export default NewUserPage