import firebaseErrors from "../constants/firebaseErrors"
import { showToastError } from "./toast"

export const getErrorMessage = (e: any) => {

    console.error("Error", e)

    if (e?.code) {
        const error = firebaseErrors.get(e.code)
        if (error) {
            return showToastError(error)
        }
    }

    return showToastError("Unespected error")

}