import { useState } from "react";
import { getErrorMessage } from "../utils/alerts";

interface Props {
    addLoading?: boolean
}

const useHandleEvent = ({ addLoading = true }: Props = {}) => {

    const [isLoading, setIsLoading] = useState(false)

    const handleEvent = async (event: () => Promise<any>) => {

        if (addLoading) {
            setIsLoading(true);
        }

        try {
            await event();
        } catch (e) {
            getErrorMessage(e)
        } finally {
            setIsLoading(false);
        }

    };

    return {
        isLoading,
        handleEvent,
    }

}

export default useHandleEvent