import { Navigate, useNavigate } from "react-router-dom"
import AuthFormBase from "../components/Auth/FormBase"
import AuthLayout from "../components/Auth/Layout"
import AuthLinks from "../components/Auth/Links"
import { SIGN_IN_LINKS } from "../components/Auth/constants"
import useAuthForm from "../components/Auth/useAuthForm"
import { ROUTES } from "../constants/routes"
import { signIn } from "../redux/modules/auth/actions"
import { useAppDispatch, useAppSelector } from "../redux/store"

const SignInPage = () => {

  const {
    isLoading, setFormData,
    email, password, handleEvent,
  } = useAuthForm()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { user } = useAppSelector(state => state.auth)
  
  if (user) return <Navigate to={ROUTES.DASHBOARD_COURSES} replace />

  const onSubmit = () => handleEvent(async () => {
    await dispatch(signIn({ email, password }))
    navigate(ROUTES.DASHBOARD_COURSES)
  })

  return (
    <>
      <AuthLayout title="Signin to Gamify" buttonText="Sign in"
        onSubmit={onSubmit} isLoading={isLoading}>
        <AuthFormBase onChange={setFormData} />
        <AuthLinks items={SIGN_IN_LINKS} />
      </AuthLayout>
    </>
  )
}

export default SignInPage
