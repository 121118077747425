import { useState } from "react"
import { removeAccents } from "../../../utils/strings"
import { PropsTable } from "./props"

interface PropsUseTable extends PropsTable {
    search?: string,
    pageSize?:number
}

const useTable = ({ body, search, pageSize = 5 }: PropsUseTable) => {

    const [currentPage, setCurrentPage] = useState(1)

    const dataFiltered = () => {

        if (!search) return body

        const newSearch = removeAccents(search)

        const filterData = (e: any) => {

            let includeValue = false

            Object.entries(e).forEach((items) => {

                if (includeValue) return

                const value = removeAccents(String(items[1]))
                includeValue = value.includes(newSearch)

            })

            return includeValue

        }

        return body.filter(filterData)

    }

    const dataPaginate = () => {

        const start = currentPage == 1 ? 0 : pageSize * (currentPage - 1)
        const end = pageSize * currentPage

        return dataFiltered().slice(start, end)

    }

    return {
        setCurrentPage,
        currentPage,
        dataFiltered,
        dataPaginate
    }

}

export default useTable