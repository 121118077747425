import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GameState } from './types';
import { GameI } from '../../../models/game';
import { RoomI } from '../../../models/room';

const initialState: GameState = {
    games: [],
    room:undefined as any,
    game: undefined as any,
    activeRooms:{},
}

const GameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setGames: (state, action: PayloadAction<GameI[]>) => {
            state.games = action.payload;
        },
        setRoom: (state, action: PayloadAction<RoomI>) => {
            state.room = action.payload;
        },
        setGame: (state, action: PayloadAction<GameI>) => {
            state.game = action.payload;
        },
        setActiveRooms: (state, action: PayloadAction<{[k:string]:RoomI}>) => {
            state.activeRooms = action.payload;
        },
    },
})

export const { setGames, setGame, setRoom, setActiveRooms } = GameSlice.actions;

export default GameSlice.reducer