import useHandleEvent from '../../../hooks/useHandleEvent'
import { RoomI } from '../../../models/room'
import { useAppSelector } from '../../../redux/store'
import { createNewGameRoom } from '../../../services/room'
import { showToastError } from '../../../utils/toast'
import ClipboardCopy from '../../Clipboard/Copy'
import UIButton from '../../UI/Button'

interface Props {
    room?: RoomI
}

const GameDetailButtonStartGame = ({ room }: Props) => {

    const adminTVLink = `https://tv.gamifyedu.com/${room?.id}`
    const goToAdminTV = () => window.open(adminTVLink, '_blank')
    const { game } = useAppSelector(state => state.game)
    const { handleEvent, isLoading } = useHandleEvent()
    const { courses } = useAppSelector(state => state.canvas)

    const handleOpenNewGame = () => handleEvent(async () => {

        const course = courses.find(e => String(e.id) === String(game.courseId))

        if(!course) return showToastError("courseId not found")

        await createNewGameRoom({ game, course })

    })

    return (
        <div className="mt-4 max-w-5xl mx-auto px-4 pb-4 sm:px-6 lg:px-8">
            {!room?.id ? (
                <div className="flex justify-start">
                    <UIButton
                        isLoading={isLoading}
                        width={'w-3/4'}
                        onClick={handleOpenNewGame}
                    >
                        Open new game
                    </UIButton>
                </div>
            ) : (
                <>
                    <ClipboardCopy
                        href={adminTVLink}
                    />
                    <div className='pt-5'>
                        <UIButton onClick={goToAdminTV} >
                            Open
                        </UIButton>
                    </div>
                </>
            )}
        </div>
    )
}

export default GameDetailButtonStartGame
