export const enum ROUTES {
    SIGN_IN='/sign-in',
    SIGN_UP='/sign-up',
    HOME='/',
    RECOVER_PASSWORD='/recover-password',
    NEW_USER="/new-user",
    DASHBOARD='/dashboard',
    GAMES='/games',
    USERS='/users',
    COURSES='/courses',
    DASHBOARD_GAMES=`${ROUTES.DASHBOARD}${ROUTES.GAMES}`,
    DASHBOARD_COURSES=`${ROUTES.DASHBOARD}${ROUTES.COURSES}`,
    DASHBOARD_USERS=`${ROUTES.DASHBOARD}${ROUTES.USERS}`
}