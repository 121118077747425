import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthState } from './types';
import { User } from 'firebase/auth';

const initialState: AuthState = {
  user: undefined as any,
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
})

export const { setUser } = AuthSlice.actions;

export default AuthSlice.reducer